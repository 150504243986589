import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { DefaultLayout } from "../layouts";
import { Header, PageWrapper, Background } from "../components";
import { white, veryDarkBlue, chartLightBlue } from "../utils";
import { FlipCard, FlipCardInner, FlipCardPadder, FlipCardFront, FlipCardBack } from '../components';

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Literature = styled.div`
    font-size: 14pt;
    margin: 5px;
    text-align: right;
    color: ${chartLightBlue};
`;

const LiteratureDate = styled.div`
    font-size: 11pt;
    text-align: right;
    margin: 5px;
    margin-top:0;
    color: ${veryDarkBlue};
`;

const LiteratureDownload = styled.a`
    font-size: 11pt;
    padding: 5px;
    width: 100%;
    background-color: ${veryDarkBlue};
    color: ${white};
    text-align: right;
    &:hover {
        text-decoration: underline;
        color: ${white};
    }
`;


class RegulatoryDisclosures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sasToken: null
        }
    }

    componentDidMount() {
        fetch(`${process.env.GATSBY_SASS_TOKEN_URL}`)
            .then(async res => {
                var result = await res.text();
                let sasToken = result.split('?').reverse()[0];
                this.setState({
                    sasToken: sasToken
                })
            });
    }

    render() {
        return (
            <DefaultLayout title="Regulatory Disclosures">
                <Background image={this.props.data.logo.publicURL}>
                    <Header color={white}>Regulatory Disclosures</Header>
                </Background>
                <PageWrapper>
                    <Boxes>
                        {this.props.data.regulatoryDisclosures.documents.map((document, index) =>
                            <FlipCard secondary={true} key={index} height="120px" width="300px">
                                <FlipCardInner secondary={true}>
                                    <FlipCardPadder secondary={true}></FlipCardPadder>
                                    <FlipCardFront secondary={true}>
                                        <Literature>
                                            {document.name}
                                        </Literature>
                                        {document.date
                                            ? (<LiteratureDate>
                                                {document.date}
                                            </LiteratureDate>)
                                            : <></>}
                                    </FlipCardFront>
                                    <FlipCardBack secondary={true} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <LiteratureDownload target="blank" href={`${document.url}?${this.state.sasToken}`}>View</LiteratureDownload>
                                    </FlipCardBack>
                                </FlipCardInner>
                            </FlipCard>
                        )}
                    </Boxes>
                </PageWrapper>
            </DefaultLayout>
        )
    }
}

export const query = graphql`
  {
    regulatoryDisclosures: regulatoryDisclosuresJson {
      documents {
        date
        name
        url
      }
    }
    logo: file(name: { eq: "about-us" }) {
      publicURL
    }
  }
`;

export default RegulatoryDisclosures;
